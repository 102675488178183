import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import coverPhoto from "../../assets/images/L&M-6.jpg";

import "react-vertical-timeline-component/style.min.css";
import { AuthContext } from "../../contexts/AuthContext.js"; // Adjust the path as needed
import EventIcon from "@mui/icons-material/Event"; // Import the EventIcon component from MUI
import { format, parseISO } from "date-fns";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HomeComponenet = () => {
  const [schedule, setSchedule] = useState([]);
  const { isAuthenticated } = useContext(AuthContext); // Use AuthContext
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
    } else {
      // Redirect to login or show a message
      console.log("User not authenticated");
    }
  }, [isAuthenticated]); // Add isAuthenticated as a dependency

  return (
    <div>
      <Box
        sx={{
          marginTop: 4, // This will still apply
          width: "85%", // Adjust this value as needed
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={coverPhoto}
          alt="landingImage"
          style={{
            width: "100%",
            justifyContent: "center",
            borderRadius: "12px",
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: 8, // This will still apply
          width: isMobile ? "100%" : "50%", // Adjust this value as needed
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "60%", // Adjust this value as needed
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h4"
            variant="h4"
            textAlign={"center"}
            sx={{ marginBottom: 4, fontSize: isMobile ? "1rem" : "2rem" }}
          >
            Thank you for choosing to share our special day with us!
          </Typography>
          <Typography
            component="body1"
            variant="body1"
            marginTop={"5px"}
            textAlign={"center"}
            sx={{ marginBottom: 4 }}
          >
            Our wedding will be held at the beautiful Cherry Glamping, in the
            Elgin Valley in the Western Cape.
            <br />
            <br />
            We have made numerous changes to the site, see the maps tab for a
            venue map, or the Things to do tab for suggestions on fun things to
            do in the area!
          </Typography>

          <Typography
            component="body1"
            variant="body1"
            marginTop={"10px"}
            textAlign={"center"}
            fontWeight={"bold"}
            sx={{ marginBottom: 4 }}
          >
            Thank you for your{" "}
            <span style={{ color: "#fd6c53", fontWeight: "bold" }}>RSVP!</span>
          </Typography>
          <Typography
            component="body2"
            variant="body2"
            marginTop={"10px"}
            textAlign={"center"}
          >
            We look forward to celebrating with you!
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default HomeComponenet;
