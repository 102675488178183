import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Navbar = ({
  userName,
  onSelect,
  onLogout,
  currentSection,
  isMobile,
  toggleDrawer,
  drawerOpen,
}) => {
  const theme = useTheme();

  const menuItems = [
    "Home",
    "Schedule",
    "Photos",
    "Accommodation",
    "RSVP",
    "FAQ",
    "Playlist",
    "Things to do",
    "Map",
    "Logout",
  ];

  const underlineStyle = {
    textDecoration: "underline",
    textDecorationColor: "#000", // Color of the underline, adjust as needed
    textDecorationThickness: "2px", // Thickness of the underline
    textUnderlineOffset: "6px", // Distance from the text to the underline
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{
        width: isMobile ? "100vw" : 250,
        height: isMobile ? "100vh" : "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile ? "center" : "flex-start",
        justifyContent: isMobile ? "center" : "flex-start",
        backgroundColor: isMobile ? "white" : "inherit",
      }}
    >
      <List>
        {menuItems.map((text) => (
          <ListItem
            button
            key={text}
            onClick={() =>
              text === "Logout"
                ? onLogout()
                : onSelect(text.replace(/\s+/g, "").toLowerCase())
            }
          >
            <ListItemText
              primary={text}
              primaryTypographyProps={{
                style:
                  text.replace(/\s+/g, "").toLowerCase() === currentSection
                    ? underlineStyle
                    : {},
              }}
              style={{ textAlign: isMobile ? "center" : "left" }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar
      position="static"
      style={{
        background: "transparent",
        boxShadow: "none",
        justifyContent: isMobile ? "space-between" : "center",
      }}
    >
      <Toolbar
        style={{
          justifyContent: isMobile ? "space-between" : "center",
          flexDirection: "row",
        }}
      >
        {!isMobile &&
          menuItems.map((text) => (
            <Button
              key={text}
              style={{
                color: "black",
                margin: "0 10px",
                ...(text.replace(/\s+/g, "").toLowerCase() === currentSection
                  ? underlineStyle
                  : {}),
                fontSize: "1rem",
                padding: "8px 16px",
                width: "auto",
                maxWidth: "none",
              }}
              onClick={() =>
                text === "Logout"
                  ? onLogout()
                  : onSelect(text.replace(/\s+/g, "").toLowerCase())
              }
            >
              {text}
            </Button>
          ))}
      </Toolbar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          style: {
            width: isMobile ? "100vw" : 250,
            height: isMobile ? "100vh" : "auto",
          },
        }}
      >
        {list()}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
