import React, { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import map from "../../assets/images/map.jpg";

const Map = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { navigate } = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      {isAuthenticated && (
        <Box
          sx={{
            marginTop: 4, // This will still apply
            width: isMobile ? "70%" : "50%", // Adjust this value as needed
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            component="body2"
            gutterBottom
            style={{ textAlign: "center" }}
          >
            For more information on the layout of the venue, please refer to the
            map below made by our lovely friend, Emily!
          </Typography>
          <img
            src={map}
            alt="map"
            style={{
              width: isMobile ? "100%" : "100%",
              justifyContent: "center",
              marginTop: "20px",
              opacity: "0.9",
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default Map;
