import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext.js"; // Adjust the path as needed
import ReactMarkdown from "react-markdown";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const FAQComponent = () => {
  const [faqs, setFaqs] = useState([]);
  const { isAuthenticated } = useContext(AuthContext); // Use AuthContext
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      setLoading(true);

      axios
        .get("/api/faq/faq/", {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        })
        .then((response) => {
          if (Array.isArray(response.data)) {
            setFaqs(response.data);
          } else {
            console.error("Data fetched is not an array:", response.data);
            setFaqs([]);
          }
          setLoading(false); // Move this inside the .then block
        })
        .catch((error) => {
          console.error("There was an error fetching the FAQs", error);
          setLoading(false); // Also set loading to false in case of an error
        });
    } else {
      // Redirect to login or show a message
      console.log("User not authenticated");
    }
  }, [isAuthenticated]); // Add isAuthenticated as a dependency

  return (
    <div>
      <Box
        sx={{
          width: isMobile ? "90%" : "100%",
          maxWidth: 600,
          mx: "auto",
          marginTop: "5px",
          display: loading ? "flex" : "block",
          justifyContent: loading ? "center" : "initial",
          alignItems: loading ? "center" : "initial",
          minHeight: loading ? "100vh" : "auto",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          faqs.map((faq) => (
            <Accordion key={faq.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${faq.id}-content`}
                id={`panel${faq.id}-header`}
              >
                <Typography
                  variant={isMobile ? "body2" : "body2"}
                  sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant={isMobile ? "body2" : "body2"}
                  sx={{
                    fontSize: isMobile ? "0.8rem" : "1rem",
                    "& ul": { paddingLeft: "40px" }, // Add padding to list items
                    "& li": { marginBottom: "8px" }, // Add margin between list items
                  }}
                >
                  <ReactMarkdown>{faq.answer}</ReactMarkdown>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>
    </div>
  );
};

export default FAQComponent;
