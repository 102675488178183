import React, { useContext, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// Import images
import railway from "../../assets/images/railway-market.jpeg";
import iona from "../../assets/images/iona-wines.webp";
import charles from "../../assets/images/charles-fox.jpg";
import paul from "../../assets/images/paul-cluver.jpeg";

const ThingsToDo = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const thingsToDoList = [
    {
      id: 1,
      task: "Elgin Railway Market",
      description:
        "The Elgin railway market is a lovely way to spend your saturday afternoon before the wedding, just don't get stuck at the bar and miss the ceremony!",
      img: railway,
      openingHours: "9am - 4pm (Saturdays)",
      link: "https://www.elginrailwaymarket.co.za/",
    },
    {
      id: 3,
      task: "Wine Tasting at Iona.",
      description:
        "Iona is a beautiful wine farm with some of the best views in the valley. The wine is pretty good too!",
      img: iona,
      openingHours: "9am - 4pm (weekdays only)",
      link: "https://www.iona.co.za/",
    },
    {
      id: 4,
      task: "Charles Fox Cap Classique Estate",
      description:
        "If you are a fan of bubbles, Charles Fox is the place to be. They have some of the best MCC in the country.",
      img: charles,
      openingHours: "10am - 4pm",
      link: "https://charlesfox.co.za/",
    },
    {
      id: 5,
      task: "Paul Cluver",
      description:
        "Paul Cluver is a great place to spend the day. They have a beautiful amphitheatre where they host concerts in the summer.",
      img: paul,
      openingHours: "8am - 4pm",
      link: "https://cluver.com/",
    },
  ];

  return (
    <div>
      {isAuthenticated && (
        <Box
          sx={{
            marginTop: 4,
            width: isMobile ? "80%" : "60%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" gutterBottom sx={{ textAlign: "center" }}>
            One of the great privileges of planning this wedding is that we've
            explored the best the Elgin valley has to offer, here are some of
            our top recommendations for things to do in the area!
          </Typography>
          <br />
          {thingsToDoList.map((item) => (
            <Box
              key={item.id}
              sx={{
                marginBottom: 4,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">{item.task}</Typography>
              <br />
              <Typography variant="body2" sx={{ maxWidth: "450px" }}>
                {item.description}
              </Typography>
              <br />
              <Typography variant="body2">
                Opening Hours: {item.openingHours}
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Website
              </Button>
              <br />
              <img
                src={item.img}
                alt={item.task}
                style={{
                  width: isMobile ? "100%" : "80%",
                  maxHeight: "400px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  marginBottom: "15px",
                }}
              />
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};

export default ThingsToDo;
