import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../contexts/AuthContext.js"; // Adjust the path as needed
import { Button, Typography, Box } from "@mui/material";
import accommodation1 from "../../assets/images/accommodation1.jpg";
import accommodation2 from "../../assets/images/accommodation2.jpeg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const AccomodationComponent = () => {
  const [accommodations, setAccomodations] = useState([]);
  const { isAuthenticated } = useContext(AuthContext); // Use AuthContext
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      //console.log(token); // Retrieve the token

      axios
        .get("/api/accommodations/accommodation/", {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        })
        .then((response) => {
          setAccomodations(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the schedule", error);
        });
    } else {
      // Redirect to login or show a message
      console.log("User not authenticated");
    }
  }, [isAuthenticated]); // Add isAuthenticated as a dependency

  return (
    <div>
      <Box
        sx={{
          marginTop: 4, // This will still apply
          width: isMobile ? "70%" : "50%", // Adjust this value as needed
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginTop: 4,
            width: isMobile ? "100%" : "90%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={accommodation1}
            alt="landingImage"
            style={{
              width: isMobile ? "100%" : "70%",
              marginRight: isMobile ? 0 : 16,
              marginBottom: isMobile ? 16 : 0,
              borderRadius: "5px",
            }}
          />
          <img
            src={accommodation2}
            alt="landingImage"
            style={{ width: isMobile ? "100%" : "70%", borderRadius: "5px" }}
          />
        </Box>
        <Box
          sx={{
            marginTop: 2,
            width: "100%",
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 4,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <img
            src={accommodation4}
            alt="landingImage"
            style={{ width: "100%", borderRadius: "5px" }}
          /> */}
        </Box>
        {accommodations ? (
          <div>
            <Typography
              component="h5"
              variant="h5"
              textAlign={"center"}
              marginBottom={"20px"}
            >
              {accommodations.display_name}
            </Typography>
            {accommodations.description &&
              accommodations.description.split("\\n").map((line, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  component="p"
                  textAlign={"center"}
                  sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}
                >
                  {line}
                  <br />
                  <br />
                </Typography>
              ))}
            {/* Display other properties as needed */}
          </div>
        ) : (
          <Typography variant="body2">No accommodation found.</Typography>
        )}
      </Box>
    </div>
  );
};

export default AccomodationComponent;
