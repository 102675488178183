import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  CircularProgress,
  Snackbar,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  TextField,
  IconButton,
} from "@mui/material";
import { Button, Modal } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext.js";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";

const EditRsvpModal = ({ rsvp, open, onClose, onSave }) => {
  const [rsvpStatus, setRsvpStatus] = useState(rsvp.rsvp_status);
  const [attending, setAttending] = useState(rsvp.attending);
  const [specialRequirements, setSpecialRequirements] = useState(
    rsvp.special_requirements
  );
  const [note, setNote] = useState(rsvp.note);
  const [stayingAtVenue, setStayingAtVenue] = useState(rsvp.staying_at_venue);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setRsvpStatus(rsvp.rsvp_status);
    setAttending(rsvp.attending);
    setStayingAtVenue(rsvp.staying_at_venue);
    setSpecialRequirements(rsvp.special_requirements);
    setNote(rsvp.note);
  }, [rsvp]);

  const handleSave = () => {
    onSave({
      ...rsvp,
      rsvp_status: "Responded",
      attending,
      staying_at_venue: stayingAtVenue,
      special_requirements: specialRequirements,
      note,
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: isMobile ? 0 : "50%",
          left: "50%",
          transform: isMobile ? "translateX(-50%)" : "translate(-50%, -50%)",
          width: isMobile ? "90%" : 400,
          height: isMobile ? "100%" : "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: isMobile ? 0 : 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4">Let us know if you can attend!</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={attending ? "yes" : "no"}
            onChange={(e) => setAttending(e.target.value === "yes")}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="I will be attending"
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="I will not be attending"
            />
          </RadioGroup>
        </FormControl>
        {attending && (
          <FormControlLabel
            control={
              <Checkbox
                checked={stayingAtVenue}
                onChange={(e) => setStayingAtVenue(e.target.checked)}
              />
            }
            label="I will be staying at the venue"
          />
        )}
        <TextField
          label="Special Requirements"
          value={specialRequirements || ""}
          onChange={(e) => setSpecialRequirements(e.target.value)}
          multiline
        />
        <TextField
          label="Note"
          value={note || ""}
          onChange={(e) => setNote(e.target.value)}
          multiline
        />
        <Button onClick={handleSave} variant="outlined">
          Save
        </Button>
      </Box>
    </Modal>
  );
};

const Rsvp = () => {
  const [rsvpData, setRsvpData] = useState([]);
  const { isAuthenticated } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedRsvp, setSelectedRsvp] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const handleEditClick = (rsvp) => {
    setSelectedRsvp(rsvp);
    setEditModalOpen(true);
  };

  const loadRSVPs = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get("/api/rsvp/rsvp_view/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRsvpData(response.data);
    } catch (error) {
      console.error("There was an error fetching the RSVPs", error);
    }
    setLoading(false);
  };

  const handleSave = async (updatedRsvp) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      await axios.patch(
        `/api/rsvp/edit_rsvp/`,
        {
          ...updatedRsvp,
          username: updatedRsvp.user_details.username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await loadRSVPs();
    } catch (error) {
      setSnackbarMessage("Error updating RSVP");
      setSnackbarSeverity("error");
      console.error("Error updating RSVP", error);
    }

    setLoading(false);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadRSVPs();
    }
  }, [isAuthenticated]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 600,
        mx: "auto",
        marginTop: "5px",
        display: loading ? "flex" : "block",
        justifyContent: loading ? "center" : "initial",
        alignItems: loading ? "center" : "initial",
        minHeight: loading ? "100vh" : "auto",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {rsvpData.map((family_rsvp) => (
            <ListItem key={family_rsvp.id} sx={{ mb: 2 }}>
              <Card variant="outlined" sx={{ width: "100%" }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Typography variant="body2" component="body2">
                          {family_rsvp.user_details.first_name}{" "}
                          {family_rsvp.user_details.last_name}
                        </Typography>
                        <Chip
                          label={
                            family_rsvp.rsvp_status === "No Response"
                              ? "No Response"
                              : family_rsvp.attending
                              ? "Attending"
                              : "Not Attending"
                          }
                          color={
                            family_rsvp.rsvp_status === "No Response"
                              ? "default"
                              : family_rsvp.attending
                              ? "success"
                              : "default"
                          }
                        />
                      </Box>
                      {/* <Button
                        variant="outlined"
                        onClick={() => handleEditClick(family_rsvp)}
                      >
                        Edit
                      </Button> */}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </ListItem>
          ))}
        </List>
      )}
      {selectedRsvp && (
        <EditRsvpModal
          rsvp={selectedRsvp}
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          onSave={handleSave}
        />
      )}
      <Typography variant="body1" sx={{ mb: 2 }}>
        RSVPs have closed, please contact us directly if you need to make any
        changes.
      </Typography>
    </Box>
  );
};

export default Rsvp;
